/**
* 定時集計 被害内訳一覧画面用モジュール。
* @module app/scheduledReport/ScheduledReportDamageListPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/ScheduledReportDamageListPage.html',
    'idis/view/page/_PageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'app/model/DisasterInfo',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/NumberTextBox',
    'dijit/form/Select',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',

    'idis/view/form/DateTimeInput',
    'app/view/form/CustomizableMunicipalitySelector',
    './ScheduledReportDamageGrid'
], function (module, declare, lang, domStyle, template, _PageBase,
    Locator, Router, Requester, Loader, IdisRest, DialogChain, DisasterInfo) {

    var filterStore = {};

    /**
    * 定時集計(各区・局)画面。
    * @class ScheduledReportPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--scheduledreport',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,
            /**
             * 各区判定フラグ
             */
            _isMunic: false,

            /**
             * コンストラクタ
             */
            constructor: function () {

                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                // URLから集計IDを取得
                this._scheduledReportId = Locator.getQuery().scheduledReportId;
                // URLから区市町村コードを取得
                this._municipalityCd = Locator.getQuery().municipalityCd;
                if ("isMunic" in Locator.getQuery()) {
                    // URLから区市町村コードを取得
                    this._isMunic = Locator.getQuery().isMunic;
                }
                if ("admNum" in Locator.getQuery()) {
                    this._admNum = Locator.getQuery().admNum;
                }

                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'damageReportId',
                    target: '/api/scheduledReport/damage',
                    rangeStartParam: 'offset',
                    rangeCountParam: 'count'
                });

                // 入力値を元にグリッド用フィルターを作成
                this.chain = DialogChain.get(this);
            },

            /**
             * DOM生成
             */
            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);

                // 被害の種類一覧設定
                this.initSetDamageTypeList();
            },

            /**
             * 画面生成完了
             */
            startup: function () {
                this.inherited(arguments);
                this._municipalityCd = Locator.getQuery().municipalityCd ? Locator.getQuery().municipalityCd :
                                        this.filter.municipalityCd;
                this.municipalityCd.set('value', this._municipalityCd);

                // グリッドを初期化する
                this.initGrid();

                // ヘッダーリンクの表示
                if (this._isMunic) {
                    this.municLink.style.display = '';
                } else {
                    this.prefLink.style.display = '';
                }
            },

            /**
             * グリッドを初期化する。
             */
            initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.grid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    this.onDetailButtonClick(evt.item);
                }));

                this.setFilterData();
                this.updateGridQuery();
            },

            // 被害の種類のセレクタ一覧を生成
            initSetDamageTypeList: function () {
                Loader.wait(Requester.get('api/code/list?type=DAMAGE_TYPE&display=true')
                    .then(lang.hitch(this, function (data) {
                        console.log(data);
                        var optionList = [{
                            label: '&nbsp;',
                            value: '',
                        }];
                        optionList = optionList.concat(data.map(function (item) {
                            return {
                                label: item.value,
                                value: item.key,
                            };
                        }));
                        this.damageType.set('options', optionList);
                    })));
            },

            /**
             * 検索パラメーターの設定
             */
            updateGridQuery: function () {

                var filter = new this.store.Filter();
                filter = filter.eq('disasterId', this._disasterId);
                filter = filter.eq('scheduledReportId', this._scheduledReportId);
                if (this.municipalityCd.get('value')) {
                    filter = filter.eq('municipalityCd', this.municipalityCd.get('value'));
                }
                if (this.admNum.get('value')) {
                    filter = filter.eq('admNum', this.admNum.get('value'));
                }
                if (this.damageType.get('value')) {
                    filter = filter.eq('damageType', this.damageType.get('value'));
                }

                // collectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.grid.set('collection', collection);
                this.setFilterStore();
            },

            /**
             * 検索条件を保存する
             */
            setFilterStore: function () {
                if (this.municipalityCd.get('value')) {
                    filterStore.municipalityCd = this.municipalityCd.get('value');
                }
                if (this.admNum.get('value')) {
                    filterStore.admNum = this.admNum.get('value');
                }
                if (this.damageType.get('value')) {
                    filterStore.damageType = this.damageType.get('value');
                }
            },

            setFilterData: function () {
                // URL上の市町区コードが異なる場合は復元しない
                var currentMunicCd = this._municipalityCd;
                if (!currentMunicCd || currentMunicCd === filterStore.municipalityCd) {
                    if (filterStore.municipalityCd) {
                        this.municipalityCd.set('value', filterStore.municipalityCd);
                    }
                }
                    if (filterStore.admNum) {
                        this.admNum.set('value', filterStore.admNum);
                    }
                    if (filterStore.damageType) {
                        this.damageType.set('value', filterStore.damageType);
                    }
            },

            /**
            * 検索
            */
            onSubmit: function () {
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                    } else {
                        console.debug('エラーあり');
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 定時集計詳細を表示
             */
            onDetailButtonClick: function (item) {
                var param = {
                    damageReportId: item.damageReportId,
                    scheduledReportId: this._scheduledReportId
                };
                if (this._isMunic) {
                    param.isMunic = this._isMunic;
                }
                if (this._admNum) {
                    param.admNum = this._admNum;
                }
                // 被害一覧画面へ遷移
                Router.moveTo('scheduledReport/damage/detail', param);
            },

            /**
             * 被害一覧を表示
             */
            onDamageReportPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();

                // 被害一覧画面へ遷移
                Router.moveTo('report');
            },
            /**
             * 定時集計一覧を表示
             */
            onListPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 被害一覧画面へ遷移
                Router.moveTo('scheduledReport');
            },
            /**
             * 定時集計 詳細を表示
             */
            onDetailPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 詳細画面に戻る
                this.leave();

            },
            /**
             * 定時集計 各区一覧
             */
            onMunicPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();

                // 各区の画面へ遷移
                Router.moveTo('scheduledReport/munic', {
                    admNum: this._admNum,
                    scheduledReportId: this._scheduledReportId
                });
            },
            /**
             * 定時集計 各区詳細
             */
            onMunicDetailLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                // 詳細画面に戻る
                this.leave();
            }
        });
});
